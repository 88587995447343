import React, { useContext, useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { doc, onSnapshot } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { Navigate, useParams } from "react-router";
import { db, functions } from "..";
import { FactSummaryDialog } from "../components/dialogs/FactSummaryDialog";
import { GuessFactOwnerDialog } from "../components/dialogs/GuessFactOwnerDialog";
import Footer from "../components/Footer";
import { LoadingView } from "../components/LoadingView";
import Navbar from "../components/Navbar";
import { CardSpotProgress, FactPosition, UTurnCard } from "../components/UTurnCard";
import { LangContext } from "../context/lang";
import { FactModelGetDTO, getAllPremadeFacts } from "../services/FactService";
import { getAllFactsNotFromCurrentPlayer } from "../services/FactService";
import { getBackgroundColourGameState, getGuessingPremadeFactsOnly, getIdentityTypeState, getLogoColourGameState, getNumberOfMaxTries, getVisibleScoreState } from "../services/GameStatesService";
import { getAllButCurrentPlayer, getAllPremadeProfiles, PlayerGetDTO } from "../services/PlayerProfileService";

export interface DialogInformation {
    factItem: FactModelGetDTO
    factPosition: FactPosition
    numberOfTriesLeft: number
}

export const UturnPage = () => {
    const {
		dispatch: { translate },
	} = useContext(LangContext);
    const emptyFactList: FactModelGetDTO[][] = []
    const emptyFactOwnerList: Array<PlayerGetDTO> = []
    const emptyCardProgress: CardSpotProgress[][] = []
    const emptyDialogInformation: DialogInformation = {
        factItem: {
            playerName: "", fact: "", playerPicture: "" 
        },
        factPosition: {
            rowIndex: -1,
            columnIndex: -1
        },
        numberOfTriesLeft: -1
    }

    // Getting URL Parameters
    const params = useParams();
    const [url, setUrl] = useState("")
    const gameId: string = params.gameId!

    //Theming States
    const [backgroundColour, setBackgroundColour] = useState("");
		const [logoColour, setLogoColour] = useState("");

    // Loading State
    const [isLoading, setIsloading] = useState(true)
    const [isGameEnding, setIsGameEnding] = useState(false)

    // Common Information
    const [facts, setFacts] = useState(emptyFactList)
    const [factOwnerDetails, setFactOwnerDetails] = useState(emptyFactOwnerList)
    const [cardProgress, setCardProgress] = useState(emptyCardProgress)
    const [numMaxTries, setNumMaxTries] = useState(0)
    const [identityType, setIdentityType] = useState("");

    // Fact for Dialog
    const [previewedFactDialogDetails, setPreviewedFactDialogDetails] = useState<DialogInformation>(emptyDialogInformation)
    const [openSubmitDialog, setOpenSubmitDialog] = useState(false)
    const [openFactSummaryDialog, setOpenFactSummary] = useState(false)

    const handleFactSummaryDialogClose = () => setOpenFactSummary(false)
    const handleSubmitDialogClose = () => setOpenSubmitDialog(false)

    // Game State for Score Visibility
    const [isScoreVisible, setIsScoreVisible] = useState(false)

    function updateCardProgressWhenCorrect() {
        const copyOfCardProgress: CardSpotProgress[][] = cloneCardProgress(cardProgress)
        const currentNumberOfTries = copyOfCardProgress[previewedFactDialogDetails.factPosition.rowIndex][previewedFactDialogDetails.factPosition.columnIndex].numOfTries
        const newCardProgress: CardSpotProgress = {cardState: 'correct', numOfTries: currentNumberOfTries}
        copyOfCardProgress[previewedFactDialogDetails.factPosition.rowIndex][previewedFactDialogDetails.factPosition.columnIndex] = newCardProgress
        localStorage.setItem("cardProgress", JSON.stringify(copyOfCardProgress))
        setCardProgress(copyOfCardProgress)
    }

    function updateCardProgressWhenIncorrect() {
        const copyOfCardProgress: CardSpotProgress[][] = cloneCardProgress(cardProgress)
        const currentNumberOfTries = copyOfCardProgress[previewedFactDialogDetails.factPosition.rowIndex][previewedFactDialogDetails.factPosition.columnIndex].numOfTries + 1
        previewedFactDialogDetails.numberOfTriesLeft = numMaxTries - currentNumberOfTries
        let newCardProgress: CardSpotProgress

        if (numMaxTries !== 0 && currentNumberOfTries >= numMaxTries) {
            newCardProgress = {cardState: 'incorrect', numOfTries: currentNumberOfTries}
        }
        else {
            newCardProgress = {cardState: 'guessing', numOfTries: currentNumberOfTries}
        }
        copyOfCardProgress[previewedFactDialogDetails.factPosition.rowIndex][previewedFactDialogDetails.factPosition.columnIndex] = newCardProgress
        
        localStorage.setItem("cardProgress", JSON.stringify(copyOfCardProgress))
        setCardProgress(copyOfCardProgress)
    }

    function cloneCardProgress(factGrid: CardSpotProgress[][]) {
        return factGrid.map((factRow) => [...factRow])
    }

    function handleFactSelectionForSubmission(factDetails: FactModelGetDTO, cardPosition: FactPosition) {
        selectFactForPreview(factDetails, cardPosition)
        setOpenSubmitDialog(true)
    }

    function handleFactSelectionForSummary(factDetails: FactModelGetDTO, cardPosition: FactPosition) {
        selectFactForPreview(factDetails, cardPosition)
        setOpenFactSummary(true)
    }

    function selectFactForPreview (factDetails: FactModelGetDTO, cardPosition: FactPosition) {
        const factDialogInformation: DialogInformation = {
            factItem: factDetails,
            factPosition: cardPosition,
            numberOfTriesLeft: numMaxTries - cardProgress[cardPosition.rowIndex][cardPosition.columnIndex].numOfTries
        }
        setPreviewedFactDialogDetails(factDialogInformation)
    }

    // Initalization of facts in the UseEffect
    useEffect(() => {
        async function fetchAllPlayerDetails(playerId: string) {
            const isGuessingPremadeOnly = await getGuessingPremadeFactsOnly(gameId)
            let playerDetails = []
            if (isGuessingPremadeOnly) {
                playerDetails = await getAllPremadeProfiles(gameId)
            } else {
                playerDetails = await getAllButCurrentPlayer(gameId, playerId)
            }
            if(playerDetails) {
                const filteredPlayerDetails = filterDuplicateDetails(playerDetails)
                setFactOwnerDetails(filteredPlayerDetails)
            }
        }

        async function initializeNumMaxTries() {
            const fetchedNumMaxTries = await getNumberOfMaxTries(gameId)
            if(fetchedNumMaxTries) {
                setNumMaxTries(fetchedNumMaxTries)
            }
        }

        function filterDuplicateDetails(playerDetails: Array<PlayerGetDTO> ) {
            const distinctPlayerDetails: Array<PlayerGetDTO> = []
            const existingEntries: Map<string, number> = new Map()
            for(const playerDetail of playerDetails) {
                if(!existingEntries.get(playerDetail.name + playerDetail.picture)) {
                    existingEntries.set(playerDetail.name + playerDetail.picture, 1) 
                    distinctPlayerDetails.push(playerDetail)
                }
            }
            return distinctPlayerDetails
        }

        async function fetchAllPlayableFacts(playerId: string) {
            const isGuessingPremadeOnly = await getGuessingPremadeFactsOnly(gameId)
            let playableFacts = []
            if (isGuessingPremadeOnly) {
                playableFacts = await getAllPremadeFacts(gameId)
                console.log(playableFacts)
            } else {
                playableFacts = await getAllFactsNotFromCurrentPlayer(gameId, playerId)
                console.log(playableFacts)
            }
            if(playableFacts) {
                const shuffledFacts = shufflePlayableFacts(playableFacts)
                const shuffledFactsMatrix: FactModelGetDTO[][] = mapFactsListToMatrix(shuffledFacts)

                // Initialize playable facts
                localStorage.setItem("facts", JSON.stringify(shuffledFactsMatrix))
                setFacts(shuffledFactsMatrix)

                // Initialize card/ game progress
                const emptyCardProgress: CardSpotProgress[][] = initializeCardProgress(shuffledFactsMatrix)
                localStorage.setItem("cardProgress", JSON.stringify(emptyCardProgress))
                setCardProgress(emptyCardProgress)
            }
        }

        function shufflePlayableFacts(playableFacts: Array<FactModelGetDTO>) {
            const playableFactsCopy = [...playableFacts]
            const shuffledFacts: Array<FactModelGetDTO> = []
            for(let i = 0; i < 25; i++) {
                const currentTotalIndexes = playableFactsCopy.length - i
                const selectedIndex: number = Math.floor(Math.random() * currentTotalIndexes)
                const removedItem = playableFactsCopy.splice(selectedIndex, 1)[0]
                shuffledFacts.push(removedItem)
            }
            return shuffledFacts
        }

        function mapFactsListToMatrix(facts: Array<FactModelGetDTO>): FactModelGetDTO[][]{
            const numberOfRows = Math.ceil(facts.length / 5)
            const numberOfColumns = 5
            const factsMatrix: FactModelGetDTO[][] = []
            for (let i = 0; i < numberOfRows; i++) {
                const rowValues: Array<FactModelGetDTO> = []
                for (let j = 0; j < numberOfColumns; j++) {
                    rowValues.push(facts[i * 5 + j])
                }
                factsMatrix.push(rowValues)
            }
            return factsMatrix
        }

        function initializeCardProgress(facts: FactModelGetDTO[][]): CardSpotProgress[][] {
            const initialIsGridItemSelected: CardSpotProgress[][] = []
            if (facts.length !== 0) {
                const numberOfRows = facts.length
                const numberOfColumns = facts[0].length
                for (let i = 0; i < numberOfRows; i++) {
                    const rowValues = []
                    for (let j = 0; j < numberOfColumns; j++) {
                        const cardSpotProgress: CardSpotProgress = {
                            cardState: 'Guessing',
                            numOfTries: 0,
                        }
                        rowValues.push(cardSpotProgress)
                    }
                    initialIsGridItemSelected.push(rowValues)
                }
            }
            return initialIsGridItemSelected
        }

        async function fetchScoreVisibleGameState() {
            const fetchedIsScoreVisible = await getVisibleScoreState(gameId) 
            if(fetchedIsScoreVisible) {
                setIsScoreVisible(fetchedIsScoreVisible)
            }
        }

        function setupGameStartListeners() {
            const unsub = onSnapshot(doc(db, "Games", gameId, "GameStates", "GameStart"), (doc) => {
                if (doc.exists()) {
                    setIsGameEnding(!doc.data().isGameStarted)
                }
            })
            return unsub
        }

        async function initializeBackgroundColour() {
			const backgroundColourGameState = await getBackgroundColourGameState(gameId);
			if (backgroundColourGameState) {
				setBackgroundColour(backgroundColourGameState);
			}
		}

		async function initializeLogoColour() {
			const logoColourGameState = await getLogoColourGameState(gameId);
			if (logoColourGameState) {
				setLogoColour(logoColourGameState);
			}
		}

        async function initializeIdentityType() {
              const fetchedGameIdentity = await getIdentityTypeState(gameId)
              setIdentityType(fetchedGameIdentity)
            }

        async function initializeGame() {
            try {
                setIsloading(true)
                const cachedUrl: string | null = localStorage.getItem("url")
                const playerURL = cachedUrl ? cachedUrl : params.playerURL
                if (!playerURL) {
                    throw new Error('Player URL Could not be Found')
                }
                setUrl(playerURL)
                const facts: string | null = localStorage.getItem("facts")
                const cardProgress: string | null = localStorage.getItem("cardProgress")
                const playerHasCachedValues = cachedUrl && facts && cardProgress

                await initializeBackgroundColour()
                await initializeLogoColour()
                await initializeNumMaxTries()
                await initializeIdentityType()
                await fetchScoreVisibleGameState()
                await fetchAllPlayerDetails(playerURL)

                if (playerHasCachedValues) {
                    setFacts(JSON.parse(facts))
                    setCardProgress(JSON.parse(cardProgress))
                } else {
                    localStorage.setItem("url", playerURL)
                    await fetchAllPlayableFacts(playerURL)
                }
            } catch (error) {
                if (error instanceof Error) {
                    const addError = httpsCallable(functions, 'addError');
                    await addError(error.message)
                }
            } finally {
                setIsloading(false)
            }
        }

        setupGameStartListeners()
        initializeGame()
        
    }, [gameId, params.playerURL])

    window.onpopstate = function () {
        window.history.go(1);
    };

    window.addEventListener("beforeunload", (ev) => {  
        ev.preventDefault();
        return ev.returnValue = 'Are you sure you want to close?';
    });
    
    if (isLoading) {
        return (
            <Box className="home">
                <LoadingView isWaitingForHost={false} showLoadingIcon={true}/>
            </Box>
        )
    } 
    
    else if(isGameEnding) {
        return (
            <Navigate to={`/${gameId}/leaderboard`} replace={true} />
        )
    }
    
    else {
        return (
            <>
                <Box className="home" style={{backgroundColor: backgroundColour}}>
                    <Navbar gameId={gameId} isAdmin={false}/>
                    <h1>{translate('uturn-title')}</h1>
                    <Container sx={{marginBottom:12}}>
                        <UTurnCard 
                            facts={facts}
                            cardProgress={cardProgress}
                            logoColour={logoColour}
                            onCardItemAnswerSubmission={handleFactSelectionForSubmission}
                            onCardItemViewSummary={handleFactSelectionForSummary}
                        />
                        <GuessFactOwnerDialog
                            isIdentityTypeNumber={identityType === "number"}
                            selectedFact={previewedFactDialogDetails.factItem}
                            numberOfTriesLeft={previewedFactDialogDetails.numberOfTriesLeft}
                            hasMaxTries={numMaxTries > 0}
                            open={openSubmitDialog} 
                            onClose={handleSubmitDialogClose} 
                            factOwners={factOwnerDetails}
                            onSubmitCorrectAnswer={updateCardProgressWhenCorrect}
                            onSubmitIncorrectAnswer={updateCardProgressWhenIncorrect}
                        />
                        <FactSummaryDialog
                            open={openFactSummaryDialog}
                            onClose={handleFactSummaryDialogClose}
                            selectedFact={previewedFactDialogDetails.factItem}
                        />
                    </Container>
                    <Footer
                        gameId={gameId}
                        cardProgress={cardProgress}
                        isScoreVisible={isScoreVisible}
                        playerId={url}
                        isScore={true}
                    />
                </Box>
            </>
        )
    }
}